// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    
  },
}

export const togglePasswordVisibilityTwo = {
  data() {
    return {
      passwordFieldTypes: 'password',
    }
  },
  methods: {
    togglePasswordVisibilityTwo() {
      this.passwordFieldTypes = this.passwordFieldTypes === 'password' ? 'text' : 'password'
    },
    
  },
}

export const togglePasswordVisibilityThree = {
  data() {
    return {
      passwordFieldTypeth: 'password',
    }
  },
  methods: {
    togglePasswordVisibilityThree() {
      this.passwordFieldTypeth = this.passwordFieldTypeth === 'password' ? 'text' : 'password'
    },
    
  },
}

export const togglePasswordVisibilityFour = {
  data() {
    return {
      passwordFieldTypefo: 'password',
    }
  },
  methods: {
    togglePasswordVisibilityFour() {
      this.passwordFieldTypefo = this.passwordFieldTypefo === 'password' ? 'text' : 'password'
    },
    
  },
}

export const togglePasswordVisibilityFive = {
  data() {
    return {
      passwordFieldTypefi: 'password',
    }
  },
  methods: {
    togglePasswordVisibilityFive() {
      this.passwordFieldTypefi = this.passwordFieldTypefi === 'password' ? 'text' : 'password'
    },
    
  },
}
export const _ = null
