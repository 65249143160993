import axios, { baseURL } from '@axios'
// import { ref } from '@vue/composition-api'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    login(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/User/Login`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
